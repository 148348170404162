import { useAppState } from 'AppContextProvider'
import { PaypalButton } from 'Components/PaypalButton.component'
import { showToast } from 'Components/Toast'
import { formatPackageDetails, getDiscountPercentage, getPaymentScreenColorPackage, getUserLanguage, usePaymentScreenColorScheme } from 'Lib'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { CustomPackage } from 'types/onboardingTypes'

import { Elements, useStripe } from '@stripe/react-stripe-js'
import { StripeElementLocale, StripeElementsOptions, StripeError } from '@stripe/stripe-js'

import PaymentMethods from './PaymentMethods'
import PricePerDay from './PricePerDay'
import TotalPrice from './TotalPrice'
import { useRef } from 'react'
import DiscountBanner from '../DiscountBanner'

export const PAYPAL_FIRST_BUTTON_COUNTRIES = ['DE', 'AT', 'CH']

const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
  text-align: center;
  margin: ${props => props.theme.spacing.large}
    ${props => props.theme.spacing.zero}
    ${props => props.theme.spacing.medium};
  color: ${({ theme }) => theme.colors.text};
`

const BuyPackageWrapper = styled.div`
  max-width: 400px;
`

const Content = styled.div`
  margin-top: ${props => props.theme.spacing.medium};
`
const SmallSpacer = styled.div`
  height: ${props => props.theme.spacing.small};
`
const DiscountBannerWrapper = styled.div`
  margin-bottom: ${props => props.theme.spacing.medium};
`

const STRIPE_ELEMENTS_APPEARANCE: StripeElementsOptions['appearance'] = {
  theme: 'flat',
  variables: {
    fontFamily: 'Fira Sans, sans-serif',
    colorPrimary: theme.colors.primary,
    colorPrimaryText: theme.colors.text,
    colorBackgroundText: theme.colors.lightText,
    fontSizeBase: theme.fontSizes.medium,
    fontLineHeight: '1.1',
    spacingAccordionItem: theme.spacing.medium,
  },
  rules: {
    '.AccordionItem': {
      border: `1px solid ${theme.colors.lightGray}`,
      marginBottom: theme.spacing.xsmall
    },
    '.Tab': {
      border: `1px solid ${theme.colors.lightGray}`,
      backgroundColor: 'white'
    },
    '.Tab--selected': {
      border: `3px solid ${theme.colors.primary}`,
      backgroundColor: 'white'
    },
    '.TabIcon--selected': {
      fill: theme.colors.primary
    },
    '.TabLabel--selected': {
      color: theme.colors.primary
    },
    '.Text--redirect': {
      fontSize: '0px'
    },
    '.Block': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: theme.colors.transparent,
      paddingBottom: '0px'
    },
    '.Label': {
      fontSize: '0px'
    }
  }
}

interface CheckoutInlineProps {
  selectedPackageId: string
  email: string
  externalStripeError?: StripeError
  discountTimeLeft: string | null
  withDiscountTimer: boolean
}

const CheckoutInline = ({ selectedPackageId, email, externalStripeError, discountTimeLeft, withDiscountTimer }: CheckoutInlineProps) => {
  const { t } = useTranslation()
  const {
    appState: {
      paypalNativeFallbackEnabled,
      paypalElementsEnabled,
      stripePrices
    }
  } = useAppState()
  const stripe = useStripe()
  const buyPackageRef = useRef<HTMLDivElement | null>(null)
  const selectedPackage = stripePrices!.find((pkg: CustomPackage) => pkg.id === selectedPackageId)!
  const recurringPackage = stripePrices!.find((price) => price.interval === 'month' && price.numberOfPeriods === 1)
  const { stripeAmount, currency, formattedBasePrice, formattedTrialPrice, formattedIntroPrice, formattedPricePerDay } = formatPackageDetails(selectedPackage, recurringPackage)

  const handleScrollToBuyPackage = () => setTimeout(() => {
    if (buyPackageRef.current) {
      buyPackageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, 300)

  const handleError = (channel: string, planId: string) => (error: any) => {
    logEvent('web_purchaseException', { channel, subscriptionModel: planId, exception: error })

    // We don't want to show an error message if the user closes the PayPal window
    return !(channel === 'paypal' && error?.message === '"Window is closed, can not determine type"')
      ? showToast('error', t('errors.unknownError', { ns: 'translation' })) : undefined
  }

  const stripeElementsOptions: StripeElementsOptions = {
    appearance: STRIPE_ELEMENTS_APPEARANCE,
    amount: stripeAmount,
    currency: currency,
    loader: 'never',
    mode: 'subscription',
    locale: (getUserLanguage() as StripeElementLocale),
    paymentMethodTypes: paypalElementsEnabled === true ? ['card', 'paypal'] : ['card'],
    fonts: [{
      // !!! maybe self hosted fonts can be used here
      cssSrc: 'https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap'
    }]
  }

  const country = localStorage.getItem('countryCode') ?? ''
  const paypalAsFirstButton = PAYPAL_FIRST_BUTTON_COUNTRIES.includes(country.toUpperCase())

  const discountPercentage = getDiscountPercentage(selectedPackage)
  const colorScheme = usePaymentScreenColorScheme()
  const colorPackage = getPaymentScreenColorPackage(colorScheme)

  return (
    <BuyPackageWrapper ref={buyPackageRef} >
      <Content>
        {!!discountPercentage &&
          <DiscountBannerWrapper>
            <DiscountBanner
              colorPackage={colorPackage}
              discountTimeLeft={discountTimeLeft}
              withDiscountTimer={withDiscountTimer}
              discountPercentage={discountPercentage}
              styleOverrides={{ margin: 0 }} />
          </DiscountBannerWrapper>}
        <PricePerDay {...{ formattedPricePerDay }} />
        <TotalPrice
          {...{ formattedBasePrice, formattedIntroPrice, selectedPackage, recurringPackage }}
        />
      </Content>
      <Title>{t('modal.chose_a_payment_method')}</Title>
      {paypalNativeFallbackEnabled &&
      <>
        <PaypalButton
          onError={handleError('paypal', selectedPackageId)}
          selectedPackage={selectedPackage}
        />
        <SmallSpacer />
      </>}
      <div id='checkout'>
        <Elements options={stripeElementsOptions} stripe={stripe}>
          <PaymentMethods
            formattedPrices={{ formattedBasePrice, formattedIntroPrice, formattedTrialPrice }}
            selectedPackage={selectedPackage}
            recurringInterval={recurringPackage?.interval}
            paypalAsFirstButton={paypalAsFirstButton}
            email={email}
            externalStripeError={externalStripeError}
            formLayout='accordion'
            onScrollDownRequest={handleScrollToBuyPackage}
          />
        </Elements>
      </div>
    </BuyPackageWrapper>)
}

export default CheckoutInline
