import styled from 'styled-components'
import { images } from 'images'
import IconInput from 'Components/IconInput'
import { FaEnvelope } from 'react-icons/fa'
import { getSearchParams, getUserLanguage, isValidEmail, mapPrivacyPolicyLink, mapTermsOfUseLink } from 'Lib'
import { useEffect, useState } from 'react'
import Button from 'Components/Button'
import { FiInfo } from 'react-icons/fi'
import { ClipLoader } from 'react-spinners'
import { useDispatch } from 'store'
import { ActionType } from 'Reducers'
import { useTranslation } from 'react-i18next'

const Center = styled.div`
  display: flex;
  justify-content: center;
`

const MaxWidth = styled.div`
  max-width: 400px;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing.small};
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  position: relative;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-left: 10px;
  margin-right: 10px;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 96px;
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.green50};
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
`

const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.green50};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`

const Divider = styled.div`
  width: 2px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0 15px;
  z-index: 1;
`

const BodyFastLogo = styled.img`
  margin-top: 5px;
  width: 120px;
  z-index: 1;
`

const WellhubLogo = styled.img`
  width: 120px;
  z-index: 1;
`

const Ellipse = styled.div`
  position: absolute;
  top: -92px;
  width: 485px;
  height: 290px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.green600};
  opacity: 0.32;
  z-index: 0;
`

const InfoWrapper = styled.div`
  display: inline-flex;
  height: 20px;
  width: fit-content;
  padding: 10px 10px;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 15px;
  border-radius: 15px;
  border: 0.5px solid var(--Colors-Green-400, #2ad7b4);
`

const InfoText = styled.p`
  color: var(--Colors-Green-900, #124f46);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
  margin-left: 5px;
`
const Error = styled.span`
  margin-top: 10px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.red800};
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; /* 18px */
`

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content:center;
  margin-top: 50px;
`
const DisclaimerWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
  color: ${({ theme }) => theme.colors.green900};
  text-align: center;
`

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.green950};
  text-decoration: underline;
`

const WellhubLoginContainer = () => {
  const dispatch = useDispatch()
  const [isValid, setIsValid] = useState<boolean>(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const { t } = useTranslation()
  const lang = getUserLanguage()

  useEffect(() => {
    const wellhubRegistrationId = getSearchParams().get('wellhubRegistrationId')
    if (wellhubRegistrationId === null) {
      logError('web_wellhubRegistrationIdNotFoundError')
      return
    }

    dispatch({
      type: ActionType.FETCH_WELLHUB_REGISTRATION_DETAILS,
      wellhubRegistrationId,
      resolve: handleEmailChange,
      reject: (_) => setLoading(false),
    })
  }, [])

  const handleEmailChange = (value: string) => {
    const isValid = isValidEmail(value)
    setIsValid(isValid)
    setEmail(value)
    setError(null)
    setLoading(false)
  }

  const handleOnBlur = () => {
    const isValid = isValidEmail(email)
    setIsValid(isValid)
    if (!isValid) {
      setError(t('errors.emailInvalid'))
    } else {
      setError(null)
    }
  }

  const handleFocus = () => {
    setIsValid(isValidEmail(email))
  }

  const handleOnSubmit = () => {
    if (!isValid) {
      return
    }

    dispatch({
      type: ActionType.WELLHUB_CHECK_USER_EXISTS,
      email,
    })
  }

  const termsOfUseUrl = mapTermsOfUseLink(lang)
  const privacyUrl = mapPrivacyPolicyLink(lang)

  return (
    <Center>
      <MaxWidth>
        <Header>
          <Ellipse />
          <LogoWrapper>
            <BodyFastLogo src={images.LogoWhite} alt="BodyFast Logo" />
            <Divider />
            <WellhubLogo src={images.wellhubLogoWhite} alt="Wellhub Logo" />
          </LogoWrapper>
        </Header>
        <TitleWrapper>
          <Subtitle>
            {t('wellhub.welcome')}
          </Subtitle>
          <Title>BodyFast</Title>
        </TitleWrapper>
        {loading ? (
          <LoadingWrapper>
            {' '}
            <ClipLoader color={'white'} size={20} speedMultiplier={1} />{' '}
          </LoadingWrapper>
        ) : (
          <ContentWrapper>
            <IconInput
              icon={<FaEnvelope />}
              name="email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              onBlur={handleOnBlur}
              onFocus={handleFocus}
              hasError={!!error}
              placeholder={t('forms.email')!}
              isInputValid={isValid === undefined ? true : isValid}
            />
            {error && <Error>{error}</Error>}
            <InfoWrapper>
              <FiInfo color={'#124F46'} />
              <InfoText>
                {t('wellhub.checkEmail')}
              </InfoText>
            </InfoWrapper>
            <Button
              type="submit"
              theme="outline"
              primaryColor="white"
              margin="50px 0 0 0"
              onClick={handleOnSubmit}
              disabled={!isValid}
            >
              {t('continueButton')} →
            </Button>
            <DisclaimerWrapper>
              <small>
                {t('genderSelection.disclaimerPart1')}
                <StyledLink href={termsOfUseUrl} target='_self'> {t('termsOfUse')} </StyledLink>
                {t('genderSelection.disclaimerPart2')}
                <StyledLink href={privacyUrl} target='_self'>{t('privacyPolicy')}</StyledLink>
                {t('genderSelection.disclaimerPart3')}
              </small>
            </DisclaimerWrapper>
          </ContentWrapper>
        )}
      </MaxWidth>
    </Center>
  )
}

export default WellhubLoginContainer
