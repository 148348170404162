
import React from 'react'
import { getUserLocaleForDateFns } from 'Lib'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useFeatureValue } from '@growthbook/growthbook-react'

interface DisclaimerProps {
  selectedInterval: string
  recurringInterval?: string
  numberOfPeriods: number
  showDiscountDisclaimer: boolean
  formattedBasePrice: string
  formattedTrialPrice: string | null
  formattedIntroPrice: string | null
}

const DisclaimerText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  text-align: center;
  color: ${({ theme }) => theme.colors.lightText};
  padding-top: ${({ theme }) => theme.spacing.small};
`

const getFormattedDateForDisclaimer = () => DateTime.now()
  .plus({ days: 7 })
  .setLocale(getUserLocaleForDateFns()?.code || DateTime.local().locale)
  .toLocaleString(DateTime.DATE_MED)
  .replace(/ /g, '\u00A0')

const PaymentDisclaimer = ({ selectedInterval, recurringInterval, numberOfPeriods, showDiscountDisclaimer, formattedBasePrice, formattedTrialPrice, formattedIntroPrice }: DisclaimerProps) => {
  const { t } = useTranslation()
  const showSameDisclaimerTexts = (useFeatureValue('payment-screen-show-same-disclaimer-texts', false))
  const interval = recurringInterval ?? selectedInterval

  const period =
  interval === 'year'
    ? t('year')
    : interval === 'month'
      ? numberOfPeriods === 1
        ? t('month_one')
        : t('month_other')
      : numberOfPeriods === 1
        ? t('week_one')
        : t('week_other')

  const disclaimerOne = showDiscountDisclaimer
    ? t('package.disclaimerDiscount',
      {
        formattedPrice: formattedTrialPrice ?? formattedBasePrice,
        formattedIntroPrice: formattedIntroPrice ?? formattedBasePrice,
        interpolation: { escapeValue: false }
      })
    : t('package.disclaimer', { formattedBasePrice, interpolation: { escapeValue: false } })

  const disclaimerTwo = t(
    selectedInterval === 'week' ? 'modal.disclaimerTrial'
      : selectedInterval === 'year' ? 'modal.disclaimerYear'
        : numberOfPeriods === 1 ? 'modal.disclaimerOneMonth'
          : 'modal.disclaimer',
    {
      formattedPrice: formattedTrialPrice ?? formattedBasePrice,
      formattedInitialPrice: formattedIntroPrice ?? formattedBasePrice,
      period,
      interval: numberOfPeriods,
      interpolation: { escapeValue: false },
      endDate: getFormattedDateForDisclaimer()
    })

  return <DisclaimerText>{showSameDisclaimerTexts ? disclaimerOne : disclaimerTwo}</DisclaimerText>
}

export default PaymentDisclaimer
