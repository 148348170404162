import React from 'react'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { useDispatch } from 'store'
import { ActionType, Selectors } from 'Reducers'
import { Trans, useTranslation } from 'react-i18next'
import Button from './Button'
import { useSelector } from 'react-redux'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { formatPriceString, getFormattedPackage } from 'Lib/PaymentUtils'
import { ManageSubscriptionStepId } from 'types/manageSubscriptionTypes'
import invariant from 'invariant'
import { DateTime } from 'luxon'
import Loading from 'Components/Loading.component'

const Container = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xLarge};
  align-items: center;
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
`

const Title = styled.h1<{ color?: string }>`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: ${(props) => props.color ?? theme.colors.text};
`

const BodyContainer = styled.div`
  background-color: ${({ theme }) => `${theme.colors.primary}11`};
  border-width: 3px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.primary};
  border-radius: 10px;
  padding: 10px;
  margin-bottom: ${({ theme }) => theme.spacing.large};
`

const Paragraph = styled.p`
  text-align: left;
`

const TextWithColor = styled.text`
  color: ${({ theme }) => theme.colors.secondary};
`

const Disclaimer = styled.div`
  font-size: ${props => props.theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.lightText};
  text-align: center;
  margin-bottom: ${props => props.theme.spacing.medium};
  margin-top: ${props => props.theme.spacing.large};
`

const CancelButtonWrapper = styled.div`
  p {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    cursor: pointer;
  }
`

const SpecialOfferStep: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const products = useSelector(Selectors.getProducts)
  const subscriptionDetails = useSelector(Selectors.getSubscriptionDetails)
  const specialOfferDurationConfig = (useFeatureValue('special-offer-duration', 'threeMonths') as SpecialOfferDuration)

  if (!products.v13 || !subscriptionDetails) {
    return <Loading />
  }

  const formattedPackage = specialOfferDurationConfig === 'threeMonths' ? getFormattedPackage(products, 'threeMonthsV13') : getFormattedPackage(products, 'oneMonthV13')

  invariant(formattedPackage, 'No formattedPackage found for special offer')
  invariant(subscriptionDetails, 'No subscription details found for special offer')
  const containsLongPrice = formattedPackage.pricePerDay >= 100

  const specialOfferPrice = formatPriceString({ price: formattedPackage.price, currency: formattedPackage.currency, withFractionDigits: !containsLongPrice, currencyDisplay: 'symbol' })
  const activeSubscriptionPrice = formatPriceString({ price: subscriptionDetails.plan.amount / 100, currency: subscriptionDetails.plan.currency, withFractionDigits: !containsLongPrice, currencyDisplay: 'symbol' })
  const activeSubscriptionDurationText = subscriptionDetails.plan.interval_count === 1 ? t(`${ManageSubscriptionStepId.SpecialOffer}.oneMonthSubscription`) : t(`${ManageSubscriptionStepId.SpecialOffer}.threeMonthsSubscription`)
  const specialOfferEndDate = DateTime.fromMillis(subscriptionDetails.current_period_end! * 1000).plus({ months: specialOfferDurationConfig === 'threeMonths' ? 3 : 1 }).toLocaleString()
  const specialOfferDurationText = specialOfferDurationConfig === 'threeMonths' ? t('month_other', { count: 3 }) : t('month_1')

  const handleSubmit = async () => {
    dispatch({ type: ActionType.ACCEPT_SPECIAL_OFFER, specialOfferDuration: specialOfferDurationConfig })
  }

  const handleCancel = async () => {
    dispatch({ type: ActionType.DECLINE_SPECIAL_OFFER })
  }

  return (
    <Container>
      <Title>
        {t(`${ManageSubscriptionStepId.SpecialOffer}.title`)}
      </Title>
      <Title color={theme.colors.secondary}>
        {t(`${ManageSubscriptionStepId.SpecialOffer}.offerText`, { specialOfferPrice, specialOfferDuration: specialOfferDurationText })}
      </Title>

      <BodyContainer>
        <Paragraph>
          <Trans
            i18nKey= {`${ManageSubscriptionStepId.SpecialOffer}.body1`}
            components={[<TextWithColor/>]}
            values={{ specialOfferDuration: specialOfferDurationText, specialOfferPrice }}
          />
        </Paragraph>
        <Paragraph>
          <Trans
            i18nKey= {`${ManageSubscriptionStepId.SpecialOffer}.body2`}
            components={[<TextWithColor/>]}
          />
        </Paragraph>
        <Paragraph>
          {t(`${ManageSubscriptionStepId.SpecialOffer}.body3`, { specialOfferPrice })}
        </Paragraph>
      </BodyContainer>

      <Button margin='0' onClick={handleSubmit}>
        {t(`${ManageSubscriptionStepId.SpecialOffer}.buttonAccept`)}
      </Button>

      <CancelButtonWrapper onClick={handleCancel}>
        <p>
          {t(`${ManageSubscriptionStepId.SpecialOffer}.buttonDecline`)}
        </p>
      </CancelButtonWrapper>
      <Disclaimer>{
        t(`${ManageSubscriptionStepId.SpecialOffer}.disclaimer`,
          {
            specialOfferEndDate,
            activeSubscriptionPrice,
            interpolation: { escapeValue: false },
            activeSubscriptionDurationText,
            specialOfferDuration: specialOfferDurationText
          })}
      </Disclaimer>
    </Container>
  )
}

export default SpecialOfferStep
