import styled from 'styled-components'

interface Props {
  fontSize?: string
  color?: string
  marginTop?: string
  marginBottom?: string
  textAlign?: string
  fontWeight?: string
  width?: string
}

const Text = styled.span<Props>`
  font-size: ${({ theme, fontSize }) => fontSize ?? theme.fontSizes.medium};
  color: ${({ theme, color }) => color ?? theme.colors.text};
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  margin-vertical: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  width: ${({ width }) => width};
`

export default Text
