import PaymentWrapper from 'Components/Payment/PaymentWrapper'
import PaymentWrapperWithModal from 'Components/Payment/PaymentWrapperWithModal'
import React, { useEffect } from 'react'
import { stripeService } from 'store'

import { useFeatureValue } from '@growthbook/growthbook-react'
import { Elements } from '@stripe/react-stripe-js'

const PaymentContainer = () => {
  const paymentFormModalOrInline = useFeatureValue('payment-modal-vs-inline', 'modal')

  useEffect(() => {
    // Users can reach to the payment screen from different places. We want to use this as an umbrella event.
    // E.g, as an activation event in growthbook
    logEvent('web_paymentMounted')
  }, [])

  return (
    <Elements stripe={stripeService}>
      {paymentFormModalOrInline === 'modal' ? <PaymentWrapperWithModal /> : <PaymentWrapper />}
    </Elements>
  )
}

export default PaymentContainer
