import { ActionType, Selectors } from 'Reducers'
import { IFetchWellhubRegistrationDetailsAction, IWellhubCheckUserExistsAction, IWellhubRegisterNewUser, IWellhubSignInAction } from 'Reducers/WellhubRedux'
import { ApiService } from 'Services'
import { take, all, call, takeLatest, put, select } from 'typed-redux-saga'
import { push } from 'connected-react-router'
import { getDownloadUrl } from 'Lib/EventsUtils'
import { race } from '@redux-saga/core/effects'
import { FirebaseError } from '@firebase/util'

function * handleFetchWellhubRegistrationDetailsSaga({ wellhubRegistrationId, resolve, reject }: IFetchWellhubRegistrationDetailsAction) {
  try {
    const registrationDetails = (yield call(ApiService.getWellhubRegistrationDetails, wellhubRegistrationId)) as {
      gympass_user_id: string
      email?: string
    }

    yield put({
      type: ActionType.SET_WELLHUB_REGISTRATION_DETAILS,
      gympass_user_id: registrationDetails.gympass_user_id,
      email: registrationDetails.email
    })

    if (registrationDetails.email) {
      yield call(logEvent, 'web_wellhub_newWellhubUserEmailFound')
      yield call(resolve, registrationDetails.email)
    }
  } catch (error) {
    yield call(reject, error)
    yield call(logError, 'web_handleFetchWellhubRegDetailsError', error)
  }
}

function * handleWellhubCheckUserExistsSaga({ email }: IWellhubCheckUserExistsAction) {
  try {
    yield call(logEvent, 'web_wellhub_checkUserExists')
    const exists: Awaited<ReturnType<typeof ApiService.checkEmailExists>> = yield call(ApiService.checkEmailExists, email)

    if (exists) {
      yield put(push('/wellhub/welcome-back'))
    } else {
      yield put(push('/wellhub/register'))
    }
  } catch (error) {
    yield call(logError, 'web_handleWellhubCheckUserExistsError', error)
  }
}

function * handleWellhubSignInSaga({ password, reject, resolve }: IWellhubSignInAction) {
  const wellhubEmail: ReturnType<typeof Selectors.getWellhubEmail> = yield select(Selectors.getWellhubEmail)
  const gympassUserId: ReturnType<typeof Selectors.getGympassUserId> = yield select(Selectors.getGympassUserId)

  if (!wellhubEmail) {
    yield call(logError, 'web_handleWellhubSignInError', `Wellhub email is missing for gympassUserId: ${gympassUserId}`)
    yield call(reject, Error('wellhubEmailMissing'))
    return
  }

  if (!gympassUserId) {
    yield call(logError, 'web_handleWellhubSignInError', 'Gympass user ID is missing')
    yield call(reject, Error('gympassUserIdMissing'))
    return
  }

  yield put({
    type: ActionType.SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST,
    email: wellhubEmail,
    password: password,
    onError: () => { },
    onSuccess: () => { }
  })

  const loginResult = (yield race({
    loginSuccess: take(ActionType.SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS),
    loginError: take(ActionType.SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED)
  })) as { loginSuccess: unknown, loginError: { error: FirebaseError } }

  if (loginResult.loginSuccess) {
    yield call(logEvent, 'web_wellhub_loginSuccess')

    yield call(ApiService.registerNewWellhubUser, gympassUserId)

    yield call(resolve)

    const downloadRoute: string = yield call(getDownloadUrl)
    yield put(push(downloadRoute, { hasOldSubscription: true }))
  } else if (loginResult.loginError) {
    yield call(logError, 'web_wellhub_wellhubSignInError', loginResult.loginError.error)
    yield call(reject, loginResult.loginError.error)
  }
}

function * handleWellhubRegisterNewUserSaga({ name, email, password, reject }: IWellhubRegisterNewUser) {
  yield put({
    type: ActionType.REGISTER_WITH_EMAIL_AND_PASSWORD,
    name: name,
    email: email,
    password: password,
    reject: () => { },
    resolve: () => { },
  })

  const registerResult = (yield race({
    registerSuccess: take(ActionType.REGISTER_WITH_EMAIL_AND_PASSWORD_SUCCESS),
    registerFailed: take(ActionType.REGISTER_WITH_EMAIL_AND_PASSWORD_FAILED)
  })) as { registerSuccess: unknown, registerFailed: { error: FirebaseError } }

  if (registerResult.registerSuccess) {
    yield call(logEvent, 'web_wellhub_registerSuccess')

    const gympassUserId: ReturnType<typeof Selectors.getGympassUserId> = yield select(Selectors.getGympassUserId)

    if (!gympassUserId) {
      yield call(logError, 'web_wellhub_registerNewUserError', 'gympass_user_id missing')
      return
    }

    yield call(ApiService.registerNewWellhubUser, gympassUserId)
  } else if (registerResult.registerFailed) {
    const { error } = registerResult.registerFailed

    yield call(reject, error)
    yield call(logError, 'web_wellhub_registerNewUserError', error)
  }
}

export function * WellhubSagas() {
  yield all([
    takeLatest(ActionType.WELLHUB_CHECK_USER_EXISTS, handleWellhubCheckUserExistsSaga),
    takeLatest(ActionType.WELLHUB_SIGN_IN, handleWellhubSignInSaga),
    takeLatest(ActionType.FETCH_WELLHUB_REGISTRATION_DETAILS, handleFetchWellhubRegistrationDetailsSaga),
    takeLatest(ActionType.WELLHUB_REGISTER_WITH_EMAIL_AND_PASSWORD, handleWellhubRegisterNewUserSaga),
  ])
}
