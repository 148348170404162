export enum ActionType {
  START_UP = 'app/START_UP',
  START_UP_SUCCESS = 'startup/START_UP_SUCCESS',
  LOADING_FINISHED = 'startup/LOADING_FINISHED',
  AUTH_INITIALIZED = 'startup/AUTH_INITIALIZED',
  STRIPE_INITIALIZED = 'startup/STRIPE_INITIALIZED',
  FIREBASE_INITIALIZED = 'startup/FIREBASE_INITIALIZED',
  FIREBASE_USER_INITIALIZED = 'app/FIREBASE_USER_INITIALIZED',
  UPDATE_USER_SUCCESS = 'login/UPDATE_USER_SUCCESS',
  UPDATE_USER_CHANGESET_REQUEST = 'login/UPDATE_USER_CHANGESET_REQUEST',
  RESOLVE_PURCHASE = 'app/RESOLVE_PURCHASE',
  ANONYMOUS_SIGN_IN_REQUEST = 'login/ANONYMOUS_SIGN_UP_REQUEST',
  UPDATE_USER_REQUEST = 'login/UPDATE_USER_REQUEST',
  LOG_OUT_REQUEST = 'login/LOG_OUT_REQUEST',
  LOGOUT_SUCCESS = 'login/LOGOUT_SUCCESS',
  LOGOUT_FAIL = 'login/LOGOUT_FAIL',
  SAVE_ONBOARDING_DATA_REQUEST = 'app/SAVE_ONBOARDING_DATA_REQUEST',
  SET_APP_BLOCKED = 'app/SET_APP_BLOCKED',
  SET_APP_UNBLOCKED = 'app/SET_APP_UNBLOCKED',
  LOGIN_SUCCESS = 'login/LOGIN_SUCCESS',
  AUTH_STATE_CHANGED = 'login/AUTH_STATE_CHANGED',
  SIGN_UP_WITH_EMAIL_REQUEST = 'login/SIGN_UP_WITH_EMAIL_REQUEST',
  SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST = 'login/SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST',
  SIGN_IN_WITH_FUTOKEN_REQUEST = 'login/SIGN_IN_WITH_FUTOKEN_REQUEST',
  SIGN_IN_WITH_GOOGLE_ONE_TAP = 'login/SIGN_IN_WITH_GOOGLE_ONE_TAP',
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST = 'login/SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST',
  SIGN_IN_WITH_GOOGLE_REQUEST = 'login/SIGN_IN_WITH_GOOGLE_REQUEST',
  SIGN_IN_WITH_APPLE_REQUEST = 'login/SIGN_IN_WITH_APPLE_REQUEST',
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS = 'login/SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS',
  SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED = 'login/SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED',
  REGISTER_WITH_GOOGLE_REQUEST = 'login/REGISTER_WITH_GOOGLE_REQUEST',
  REGISTER_WITH_APPLE_REQUEST = 'login/REGISTER_WITH_APPLE_REQUEST',
  UPGRADE_USER_WITH_GOOGLE_REQUEST = 'login/UPGRADE_USER_WITH_GOOGLE_REQUEST',
  UPGRADE_USER_WITH_APPLE_REQUEST = 'login/UPGRADE_USER_WITH_APPLE_REQUEST',
  CHECK_EMAIL_EXISTS_REQUEST = 'login/CHECK_EMAIL_EXISTS_REQUEST',
  RESET_PASSWORD_REQUEST = 'login/RESET_PASSWORD_REQUEST',
  FETCH_SUBSCRIPTION_DETAILS = 'login/FETCH_SUBSCRIPTION_DETAILS',
  SET_SUBSCRIPTION_DETAILS = 'login/SET_SUBSCRIPTION_DETAILS',
  CREATE_STRIPE_SESSION_REQUEST = 'purchase/CREATE_STRIPE_SESSION_REQUEST',
  HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE = 'app/HANDLE_NAVIGATING_TO_POST_ONBOARDING_ROUTE',
  REGISTER_WITH_EMAIL_AND_PASSWORD = 'login/REGISTER_WITH_EMAIL_AND_PASSWORD',
  REGISTER_WITH_EMAIL_AND_PASSWORD_SUCCESS = 'login/REGISTER_WITH_EMAIL_AND_PASSWORD_SUCCESS',
  REGISTER_WITH_EMAIL_AND_PASSWORD_FAILED = 'login/REGISTER_WITH_EMAIL_AND_PASSWORD_FAILED',
  COMMIT_MANAGE_SUBSCRIPTION_STEP = 'manageSubscription/COMMIT_MANAGE_SUBSCRIPTION_STEP',
  ACCEPT_SPECIAL_OFFER = 'manageSubscription/ACCEPT_SPECIAL_OFFER',
  DECLINE_SPECIAL_OFFER = 'manageSubscription/DECLINE_SPECIAL_OFFER',
  CANCEL_SUBSCRIPTION = 'manageSubscription/CANCEL_SUBSCRIPTION',
  HANDLE_LOGIN_SUCCESS = 'manageSubscription/HANDLE_LOGIN_SUCCESS',
  CLOSE_IN_APP_BROWSER = 'manageSubscription/CLOSE_IN_APP_BROWSER',
  LOAD_PRODUCTS = 'payment/LOAD_PRODUCTS',
  MERGE_PRODUCTS = 'payment/MERGE_PRODUCTS',
  FETCH_WELLHUB_REGISTRATION_DETAILS = 'wellhub/FETCH_WELLHUB_REGISTRATION_DETAILS',
  SET_WELLHUB_REGISTRATION_DETAILS = 'wellhub/SET_WELLHUB_REGISTRATION_DETAILS',
  WELLHUB_CHECK_USER_EXISTS = 'wellhub/WELLHUB_CHECK_USER_EXISTS',
  SET_WELLHUB_REGISTRATION_EMAIL = 'wellhub/SET_WELLHUB_REGISTRATION_EMAIL',
  WELLHUB_SIGN_IN = 'wellhub/WELLHUB_SIGN_IN',
  UPDATE_SUBSCRIPTION = 'payment/UPDATE_SUBSCRIPTION',
  DECLINE_UPDATE_SUBSCRIPTION_OFFER = 'payment/DECLINE_UPDATE_SUBSCRIPTION_OFFER',
  WELLHUB_REGISTER_WITH_EMAIL_AND_PASSWORD = 'wellhub/WELLHUB_REGISTER_WITH_EMAIL_AND_PASSWORD'
}
