import { formatPriceString } from 'Lib/PaymentUtils'
import { CustomPackage, PackageTagId } from 'types/onboardingTypes'

export function getRecurringAndTrialId(selectedPackage: CustomPackage, recurringPackageId: string, packages: CustomPackage[]) {
  let trialId

  if (selectedPackage.tagId === PackageTagId.Trial) {
    // invert package id and trial package id, so the main ID is the 1 month value, and the trialId is not empty
    recurringPackageId = packages.find((price) => price.interval === 'month' && price.numberOfPeriods === 1)!.id

    trialId = selectedPackage.id
  }

  return { recurringPackageId, trialId }
}

export const formatPackageDetails = (
  { discountedPriceInCents, priceInCents, currency, price, tagId, discountedPrice, discountedPricePerDay, pricePerDay }: CustomPackage,
  recurringPackage?: CustomPackage) =>
  ({
    stripeAmount: discountedPriceInCents ?? priceInCents,
    currency: currency.toLowerCase(),
    formattedBasePrice: formatPriceString({ price, currency, withFractionDigits: true }),
    formattedTrialPrice: tagId === PackageTagId.Trial ? formatPriceString({ price: recurringPackage!.price, currency: recurringPackage!.currency, withFractionDigits: true }) : null,
    formattedIntroPrice: discountedPrice ? formatPriceString({ price: discountedPrice, currency, withFractionDigits: true }) : null,
    formattedPricePerDay: formatPriceString({ price: discountedPricePerDay ?? pricePerDay, currency, withFractionDigits: pricePerDay < 100 })
  })
