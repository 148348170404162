import { GrowthBook } from '@growthbook/growthbook-react'
import { getLocalId } from 'Lib/AppUtils'

let growthbook: GrowthBook | null

const CLIENT_KEY = __DEV__ ? 'sdk-zXGj7gy9I1YyX6K' : 'sdk-P8QFKTxJZwv1nSDQ'

// Define all your feature flags and types here
interface AppFeatures {
  'unauth-route-directs-to': 'loading' | 'login'
  'upsell-funnel-active': boolean
}

export const getGrowthbookInstance = (): GrowthBook<AppFeatures> => {
  if (!growthbook) {
    growthbook = new GrowthBook<AppFeatures>({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: CLIENT_KEY,
      enableDevMode: __DEV__,
      subscribeToChanges: true,
      trackingCallback: (experiment, result) => {
        // this is important for starting A/B test. Don't remove it or change the name of the event
        logEvent('experiment_viewed', {
          // Don't change the variable names. They are used by GrowthBook. According to their doc, they are suggesting to use experimentId and variationId.
          // However, when they reading "Experiment Assignment Queries" for the Data Sources, they are using experiment_id and variation_id as default.
          experiment_id: experiment.key,
          variation_id: result.key
        })
      }
    })
  }

  return growthbook
}

export const setGrowthbookAttributes = (newAttributes: Record<string, string>) => {
  const growthbook = getGrowthbookInstance()

  const oldAttributes = growthbook.getAttributes() ?? {}
  const attributes = {
    // Keep the old attributes below the new ones. So that the first values wouldn't be overwritten
    ...newAttributes,
    ...oldAttributes
  }

  growthbook.setAttributes(attributes)
}

setGrowthbookAttributes({ local_id: getLocalId() })

export const getFeatureValue = <K extends keyof AppFeatures>(key: K, defaultValue: AppFeatures[K]): AppFeatures[K] => {
  const growthbook = getGrowthbookInstance()

  return growthbook.getFeatureValue(key, defaultValue) as AppFeatures[K]
}
