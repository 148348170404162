import {
  de,
  deAT,
  enAU,
  enCA,
  enGB,
  enIE,
  enIN,
  enNZ,
  enUS,
  es,
  fr,
  frCA,
  frCH,
  it,
  pt,
  ptBR
} from 'date-fns/locale'
import i18n from 'i18n'

export const fallbackToKnownLang = (lang: string | null) => !lang || !['de', 'en', 'es', 'fr', 'pt', 'it'].includes(lang.toLowerCase()) ? 'en' : lang
const fallbackLangToCountry = (lang: string) => lang === 'en' ? 'US' : lang.toUpperCase()

export function getUserLanguageAndCountry(): [string, string] {
  const langCountryTuple: [string, string] = ['en', 'US'] // just placeholders

  const language: string =
    (window.navigator as any).userLanguage ?? // necessary to check for userLanguage on older browsers
    window.navigator.language ??
    ''

  const langTokens = language.split('-')

  // the language on the i18n object gets set on the main router when the app loads (and parses the `lang` param)
  if (i18n.language || langTokens[0]) {
    langCountryTuple[0] = fallbackToKnownLang(i18n.language?.substring(0, 2) ?? langTokens[0])
  }

  if (localStorage.getItem('countryCode')) {
    langCountryTuple[1] = localStorage.getItem('countryCode') ?? fallbackLangToCountry(langTokens[0])
  } else if (langTokens[1]) {
    langCountryTuple[1] = langTokens[1].toUpperCase()
  } else {
    // TODO once sepa branch is merged also retrieve the country code from the IP tracking call
    langCountryTuple[1] = fallbackLangToCountry(langTokens[0])
  }

  return langCountryTuple
}

export function getUserCountry() {
  const [, country] = getUserLanguageAndCountry()

  return country
}

export function getUserLanguage(): string {
  return fallbackToKnownLang(i18n.language.split('-')[0].toLowerCase())
}

export function getUserRegion () {
  return localStorage.getItem('region') ?? 'unknown'
}

export function mapCurrencySymbol(currency: string) {
  switch (currency) {
    case 'usd': return '$'
    case 'eur': return '€'
    case 'brl': return 'R$'
    case 'uyu': return '$U'
    case 'pen': return 'S/'
    case 'mxn': return 'Mex$'
    case 'cop': return 'COL$'
    case 'hnl': return 'L'
    case 'crc': return '₡'
    case 'clp': return '(chil.) $'
    case 'bob': return 'Bs'
    case 'ars': return 'arg$'
    case 'gbp': return '£'
    default: return currency.toUpperCase() // e.g. CHF and CAD
  }
}

const localesMap: { [key: string]: Locale } = {
  de,
  deAT,
  es,
  enAU,
  enCA,
  enGB,
  enIE,
  enIN,
  enNZ,
  enUS,
  frCA,
  frCH,
  fr,
  pt,
  ptBR,
  it
}

export function getUserLocaleForDateFns() {
  const [lang, country] = getUserLanguageAndCountry()
  return localesMap[`${lang}${country}`] ?? localesMap[lang] ?? enUS
}

const dateFormats: {
  [key: string]: string
} = {
  de: 'dd.MM.yyyy',
  deAT: 'dd.MM.yyyy',
  es: 'dd/MM/yyyy',
  enAU: 'dd/MM/yyyy',
  enCA: 'yyyy/MM/dd',
  enGB: 'dd/MM/yyyy',
  enIE: 'dd/MM/yyyy',
  enIN: 'dd-MM-yyyy',
  enNZ: 'dd/MM/yyyy',
  enUS: 'MM/dd/yyyy',
  frCA: 'yyyy-MM-dd',
  frCH: 'dd.MM.yyyy',
  fr: 'dd/MM/yyyy',
  pt: 'dd/MM/yyyy',
  ptBR: 'dd/MM/yyyy',
  it: 'dd/MM/yyyy'
}

export function getUserLocaleDateFormat() {
  const [lang, country] = getUserLanguageAndCountry()

  return dateFormats[`${lang}${country}`] ?? dateFormats[lang] ?? dateFormats.en
}

export const getUserLocaleString = () => i18n.language

const GDPR_COUNTIES: { [key: string]: boolean } = {
  AT: true,
  BE: true,
  BG: true,
  HR: true,
  CY: true,
  CZ: true,
  DK: true,
  EE: true,
  FI: true,
  FR: true,
  DE: true,
  EL: true,
  HU: true,
  IE: true,
  IT: true,
  LV: true,
  LT: true,
  LU: true,
  MT: true,
  NL: true,
  PL: true,
  PT: true,
  RO: true,
  SK: true,
  SI: true,
  ES: true,
  SE: true,
  UK: true
}

export const isGdprOrCalifornia = () => {
  const country = getUserCountry()
  const region = getUserRegion()

  return !!GDPR_COUNTIES[country] || region === 'CA'
}
