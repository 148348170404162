import { useAppState } from 'AppContextProvider'
import Button from 'Components/Button'
import { getDiscountPercentage } from 'Lib'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CustomPackage, PaymentColorPackage } from 'types/onboardingTypes'

const FixedHeader = styled.div<{ scrollPositionAtTop: boolean, hideHeader: boolean }>`
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 50px;
  opacity: ${({ hideHeader }) => (hideHeader ? '0' : '1')};
  top: 0;
  background-color: white;
  padding: ${props => props.theme.spacing.small};
  left: 0px;
  right: 0px;
  box-shadow: ${({ scrollPositionAtTop }) => (scrollPositionAtTop ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)')};
  transition: box-shadow 0.3s ease-in-out, height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`

const BuyButtonWrapper = styled.div`
  max-width: 50%;
`

const DiscountText = styled.div`
 font-size: ${props => props.theme.fontSizes.xsmall};
`

const DiscountCountdown = styled.strong`
  font-size: ${props => props.theme.fontSizes.large};
`

interface PaymentHeaderProps {
  onTap: () => void
  scrollPositionAtTop: boolean
  colorPackage: PaymentColorPackage
  selectedPackageId: string | null
  discountTimeLeft: string | null
  hideHeader?: boolean
}

const PaymentHeader = ({ selectedPackageId, discountTimeLeft, colorPackage, onTap, scrollPositionAtTop, hideHeader = false }: PaymentHeaderProps) => {
  const { t } = useTranslation()
  const { appState } = useAppState()

  // selected package is null when refreshing payment page and stripe needs to be re-initialized
  const selectedPackage = appState.stripePrices?.find((pkg: CustomPackage) => pkg.id === selectedPackageId)

  if (!selectedPackage) {
    return null
  }

  const discountPercentage = getDiscountPercentage(selectedPackage)

  return (
    <FixedHeader scrollPositionAtTop={scrollPositionAtTop} hideHeader={hideHeader}>
      <div>
        {!!discountPercentage && discountTimeLeft && (
          <>
            <DiscountText>{t('package.discountAvailableFor', { discountPercentage })}</DiscountText>
            <DiscountCountdown>{discountTimeLeft}</DiscountCountdown>
          </>
        )}
      </div>
      <BuyButtonWrapper>
        <Button
          padding='15px 15px'
          margin='0px'
          onClick={() => onTap()}
          enablePulse
          primaryColor={colorPackage.buttonColor}
        >
          {t('package.confirmButton')}
        </Button>
      </BuyButtonWrapper>
    </FixedHeader>
  )
}

export default PaymentHeader
